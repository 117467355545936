import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { empty, equal, notEmpty } from "@ember/object/computed";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { or } from "truth-helpers";
import GlimmerComponentWithDeprecatedParentView from "discourse/components/glimmer-component-with-deprecated-parent-view";
import concatClass from "discourse/helpers/concat-class";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
import icon from "discourse-common/helpers/d-icon";
import deprecated from "discourse-common/lib/deprecated";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ACTION_AS_STRING_DEPRECATION_ARGS = ["DButton no longer supports @action as a string. Please refactor to use an closure action instead.", {
  id: "discourse.d-button-action-string"
}];
export default class DButton extends GlimmerComponentWithDeprecatedParentView {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "btnIcon", [notEmpty("args.icon")]))();
  #btnIcon = (() => (dt7948.i(this, "btnIcon"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "btnLink", [equal("args.display", "link")]))();
  #btnLink = (() => (dt7948.i(this, "btnLink"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "noText", [empty("computedLabel")]))();
  #noText = (() => (dt7948.i(this, "noText"), void 0))();
  constructor() {
    super(...arguments);
    if (typeof this.args.action === "string") {
      deprecated(...ACTION_AS_STRING_DEPRECATION_ARGS);
    }
  }
  get forceDisabled() {
    return !!this.args.isLoading;
  }
  get isDisabled() {
    return this.forceDisabled || this.args.disabled;
  }
  get btnType() {
    if (this.args.icon) {
      return this.computedLabel ? "btn-icon-text" : "btn-icon";
    } else if (this.computedLabel) {
      return "btn-text";
    }
  }
  get computedTitle() {
    if (this.args.title) {
      return I18n.t(this.args.title);
    }
    return this.args.translatedTitle;
  }
  get computedLabel() {
    if (this.args.label) {
      return I18n.t(this.args.label);
    }
    return this.args.translatedLabel;
  }
  get computedAriaLabel() {
    if (this.args.ariaLabel) {
      return I18n.t(this.args.ariaLabel);
    }
    if (this.args.translatedAriaLabel) {
      return this.args.translatedAriaLabel;
    }
  }
  get computedAriaExpanded() {
    if (this.args.ariaExpanded === true) {
      return "true";
    }
    if (this.args.ariaExpanded === false) {
      return "false";
    }
  }
  keyDown(e1) {
    if (this.args.onKeyDown) {
      e1.stopPropagation();
      this.args.onKeyDown(e1);
    } else if (e1.key === "Enter") {
      this._triggerAction(e1);
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "keyDown", [action]))();
  click(event1) {
    return this._triggerAction(event1);
  }
  static #_6 = (() => dt7948.n(this.prototype, "click", [action]))();
  mouseDown(event1) {
    if (this.args.preventFocus) {
      event1.preventDefault();
    }
  }
  static #_7 = (() => dt7948.n(this.prototype, "mouseDown", [action]))();
  _triggerAction(event1) {
    const {
      action: actionVal1,
      route: route1
    } = this.args;
    if (actionVal1 || route1) {
      if (actionVal1) {
        const {
          actionParam: actionParam1,
          forwardEvent: forwardEvent1
        } = this.args;
        if (typeof actionVal1 === "string") {
          deprecated(...ACTION_AS_STRING_DEPRECATION_ARGS);
          if (this._target?.send) {
            this._target.send(actionVal1, actionParam1);
          } else {
            throw new Error("DButton could not find a target for the action. Use a closure action instead");
          }
        } else if (typeof actionVal1 === "object" && actionVal1.value) {
          runAfterFramePaint(() => forwardEvent1 ? actionVal1.value(actionParam1, event1) : actionVal1.value(actionParam1));
        } else if (typeof actionVal1 === "function") {
          runAfterFramePaint(() => forwardEvent1 ? actionVal1(actionParam1, event1) : actionVal1(actionParam1));
        }
      } else if (route1) {
        this.router.transitionTo(route1);
      }
      event1.preventDefault();
      event1.stopPropagation();
      return false;
    }
  }
  get wrapperElement() {
    const {
      href,
      type
    } = this.args;
    return href ? setComponentTemplate(createTemplateFactory(
    /*
      <a href={{href}} ...attributes>{{yield}}</a>
    */
    {
      "id": "Zta65cHU",
      "block": "[[[11,3],[16,6,[32,0]],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-button.js",
      "scope": () => [href],
      "isStrictMode": true
    }), templateOnly()) : setComponentTemplate(createTemplateFactory(
    /*
      
              <button type={{or type "button"}} ...attributes>{{yield}}</button>
            
    */
    {
      "id": "xB7visOb",
      "block": "[[[1,\"\\n          \"],[11,\"button\"],[16,4,[28,[32,0],[[32,1],\"button\"],null]],[17,1],[12],[18,2,null],[13],[1,\"\\n        \"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-button.js",
      "scope": () => [or, type],
      "isStrictMode": true
    }), templateOnly());
  }
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{! template-lint-disable no-pointer-down-event-binding }}
      <this.wrapperElement
        {{! For legacy compatibility. Prefer passing class as attributes. }}
        class={{concatClass
          @class
          (if @isLoading "is-loading")
          (if this.btnLink "btn-link" "btn")
          (if this.noText "no-text")
          this.btnType
        }}
        {{! For legacy compatibility. Prefer passing these as html attributes. }}
        id={{@id}}
        form={{@form}}
        aria-controls={{@ariaControls}}
        aria-expanded={{this.computedAriaExpanded}}
        tabindex={{@tabindex}}
        disabled={{this.isDisabled}}
        title={{this.computedTitle}}
        aria-label={{this.computedAriaLabel}}
        ...attributes
        {{on "keydown" this.keyDown}}
        {{on "click" this.click}}
        {{on "mousedown" this.mouseDown}}
      >
        {{#if @isLoading}}
          {{~icon "spinner" class="loading-icon"~}}
        {{else if @icon}}
          {{#if @ariaHidden}}
            <span aria-hidden="true">
              {{~icon @icon~}}
            </span>
          {{else}}
            {{~icon @icon~}}
          {{/if}}
        {{/if}}
  
        {{~#if this.computedLabel~}}
          <span class="d-button-label">
            {{~htmlSafe this.computedLabel~}}
            {{~#if @ellipsis~}}
              &hellip;
            {{~/if~}}
          </span>
        {{~else if (or @icon @isLoading)~}}
          &#8203;
          {{! Zero-width space character, so icon-only button height = regular button height }}
        {{~/if~}}
  
        {{yield}}
      </this.wrapperElement>
    
  */
  {
    "id": "rdE8bvar",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[8,[30,0,[\"wrapperElement\"]],[[16,0,[28,[32,0],[[30,1],[52,[30,2],\"is-loading\"],[52,[30,0,[\"btnLink\"]],\"btn-link\",\"btn\"],[52,[30,0,[\"noText\"]],\"no-text\"],[30,0,[\"btnType\"]]],null]],[16,1,[30,3]],[16,\"form\",[30,4]],[16,\"aria-controls\",[30,5]],[16,\"aria-expanded\",[30,0,[\"computedAriaExpanded\"]]],[16,\"tabindex\",[30,6]],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[16,\"title\",[30,0,[\"computedTitle\"]]],[16,\"aria-label\",[30,0,[\"computedAriaLabel\"]]],[17,7],[4,[32,1],[\"keydown\",[30,0,[\"keyDown\"]]],null],[4,[32,1],[\"click\",[30,0,[\"click\"]]],null],[4,[32,1],[\"mousedown\",[30,0,[\"mouseDown\"]]],null]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,[28,[32,2],[\"spinner\"],[[\"class\"],[\"loading-icon\"]]]]],[]],[[[41,[30,8],[[[41,[30,9],[[[1,\"          \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,[28,[32,2],[[30,8]],null]],[13],[1,\"\\n\"]],[]],[[[1,[28,[32,2],[[30,8]],null]]],[]]],[1,\"      \"]],[]],null]],[]]],[41,[30,0,[\"computedLabel\"]],[[[10,1],[14,0,\"d-button-label\"],[12],[1,[28,[32,3],[[30,0,[\"computedLabel\"]]],null]],[41,[30,10],[[[1,\"…\"]],[]],null],[13]],[]],[[[41,[28,[32,4],[[30,8],[30,2]],null],[[[1,\"​\\n\"]],[]],null]],[]]],[18,11,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@class\",\"@isLoading\",\"@id\",\"@form\",\"@ariaControls\",\"@tabindex\",\"&attrs\",\"@icon\",\"@ariaHidden\",\"@ellipsis\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-button.js",
    "scope": () => [concatClass, on, icon, htmlSafe, or],
    "isStrictMode": true
  }), this))();
}